import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import ContactForm from "../components/contactForm";
import {Link} from "gatsby";
import Img from "gatsby-image"
import {useStaticQuery, graphql} from "gatsby"

import paypalDonate from "../images/Paypal-Spenden-Button.png";


const IndexPage = () => {
    const imgData = useStaticQuery(graphql`
      query jpbLogoQueryAndBioImageQuery {
      logoIcon: file(relativePath: {eq: "logo_icon_full.png"}) {
        childImageSharp {
          fixed(height: 360) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bioImage: file(relativePath: {eq: "dresden_coa_4.JPG"}) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
      bioImage2: file(relativePath: {eq: "saint_saens_recording.jpeg"}) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
        hadubrantImage: file(relativePath: {eq: "hadubrant_1.jpg"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        zeuthenImage: file(relativePath: {eq: "zeuthen_neujahrskonzert.jpg"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        jugendDirigiertImage: file(relativePath: {eq: "jugend_dirigiert.jpg"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mozartCmollImage: file(relativePath: {eq: "mozart_c-moll-3.jpg"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dresdenCOAImage: file(relativePath: {eq: "dresden_coa_1.JPG"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        verdiRequiemImage: file(relativePath: {eq: "berliner_dom_frosch.jpg"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        missaSolemnisImage: file(relativePath: {eq: "missa_solemnis_1.jpg"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        saintSaensImage: file(relativePath: {eq: "saint_saens_recording.jpeg"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dresdenCOAImage2101: file(relativePath: {eq: "dresden_coa_21_3.jpg"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dresdenCOAImage2102: file(relativePath: {eq: "dresden_coa_21_2.jpg"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        verdiRequiem23Image: file(relativePath: {eq: "verdi_requiem_23.jpg"}) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
    `);

    const biographyOverviewData = {
        Founded: "2013",
        Conductors: ["Julien Salemkour", "Marcus Merkel"],
        Soloists: ["Khatia Buniatishvili", "Sir Bryn Terfel", "Peter Seiffert", "René Pape", "Barbara Krieger", "Marius Vlad", "Tobias Schabel", "Anna Lapkovskaja"]
    }

    const projectsData = [
        {
            img: imgData.hadubrantImage.childImageSharp.fluid,
            title: `Uraufführung "hadubrant"`,
            location: `Gethsemanekirche Berlin`,
            time: `März 2013`,
            fullText: `Uraufführung eines Szenischen Oratoriums für Chor, Solisten und Orchester von Arnold Fritzsch, in Zusammenarbeit mit dem Oberstufenchor des Rosa-Luxemburg-Gymnasiums Berlin-Pankow (unter der Leitung von Veit Römer)`,
            conductor: `Marcus Merkel`,
            soloists: ["Kai Wegner"],
        },
        {
            img: imgData.zeuthenImage.childImageSharp.fluid,
            title: `Neujahrskonzert für die Gemeinde Zeuthen`,
            location: `Zeuthen`,
            time: `Januar 2014`,
            fullText: `Die Junge Philharmonie Berlin spielte ein Neujahrskonzert für die Gemeinde Zeuthen in einer hochkarätigen Streichorchesterbesetzung.`,
            conductor: `Marcus Merkel`,
            soloists: ["Prof. Heinz Schunk", "Ulrike Petersen"],
        },
        {
            img: imgData.jugendDirigiertImage.childImageSharp.fluid,
            title: `Jugend Dirigiert`,
            location: `Berlin`,
            time: `2015-2018`,
            fullText: `Die JPB organisierte, kuratierte und co-veranstaltete zahlreiche Projekte, Arbeitsphasen und Konzerte zur Förderung von Kindern und Jugendlichen. Nicht nur Musikerziehung, sondern auch die Erfahrung der eigenen Persönlichkeit vor dem Orchester standen im Mittelpunkt. Die jungen Dirigenten erhielten vom Orchester sofortiges Feedback und von den Tutoren Übungen zum eigenen Auftreten, der eigenen Souveränität und natürlich zum musikalischen Verständnis. Höhepunkte des Projekts waren - Auftritte im Konzerthaus Berlin und in der Jesus-Christus-Kirche Berlin-Dahlem.`,
            conductor: null,
            soloists: null,
        },
        {
            img: imgData.mozartCmollImage.childImageSharp.fluid,
            title: `Mozart: Große Messe in c-moll`,
            location: `Berliner Dom`,
            time: `März 2019`,
            fullText: `Erstmals spielte die JPB im Berliner Dom mit Mozarts Messe in c-moll. Dies markierte auch den Beginn der Zusammenarbeit mit dem Ernst-Senff-Chor Berlin und seinem Leiter Steffen Schubert.`,
            conductor: `Marcus Merkel`,
            soloists: ["Barbara Krieger", "Narine Yeghiyan", "Goran Cah", "Haakon Schaub"],
        },
        {
            img: imgData.dresdenCOAImage.childImageSharp.fluid,
            title: `Operngala - Classic Open Air Dresden`,
            location: `Neumarkt, Dresden`,
            time: `August 2019`,
            fullText: `Eine besondere Ehre war es, mit Größen wie Peter Seiffert und René Pape auf einer Bühne zu stehen. Mit großem Erfolg spielte die JPB unter freiem Himmel für 5.000 Zuschauer. Erstmals dirigierte der Berliner Staatskapellmeister Julien Salemkour`,
            conductor: `Julien Salemkour`,
            soloists: ["Barbara Krieger", "Peter Seiffert", "René Pape"],
        },
        {
            img: imgData.verdiRequiemImage.childImageSharp.fluid,
            title: `Verdi: Messa da Requiem`,
            location: `Berliner Dom`,
            time: `November 2019`,
            fullText: `Nach dem großen Erfolg mit Mozarts c-moll-Messe wurde die JPB abermals in den Berliner Dom eingeladen, diesmal mit der "Messa da Requiem" von Giuseppe Verdi, und wiederum in Zusammenarbeit mit dem Ernst-Senff-Chor Berlin.`,
            conductor: `Julien Salemkour`,
            soloists: ["Barbara Krieger", "Anna Lapkovskaja", "Peter Sonn", "Tobias Schabel"],
        },
        {
            img: imgData.missaSolemnisImage.childImageSharp.fluid,
            title: `Beethoven: Missa Solemnis`,
            location: `Berliner Dom`,
            time: `Februar 2020`,
            fullText: `Noch kurz bevor die Corona-Krise den Kulturbetrieb zum Erliegen brachte, spielte die JPB im Berliner Dom Beethovens "Missa Solemnis" mit einer hochkarätigen Solistenbesetzung und dem Ernst-Senff-Chor Berlin, unter der Gesamtleitung von Julien Salemkour.`,
            conductor: `Julien Salemkour`,
            soloists: ["Barbara Krieger", "Anna Lapkovskaja", "Stefan Heibach", "Tobias Schabel"],
        },
        {
            img: imgData.saintSaensImage.childImageSharp.fluid,
            title: `Fernsehaufzeichnung "Karneval der Tiere" für ARTE`,
            location: `Theater im Delphi, Berlin`,
            time: `August 2020`,
            fullText: `In einer höchstkarätigen Kammerbesetzung spielte die Junge Philharmonie Berlin in einer Fernsehaufzeichnung Saint-Saëns' "Karneval der Tiere" für ARTE ein. Die Dokumentation zu Saint-Saëns' Jubiläumsjahr wird voraussichtlich im Dezember 2021 auf ARTE zu sehen sein.`,
            conductor: `Marcus Merkel`,
            soloists: ["Schaghajegh Nosrati", "Jean-Sélim Abdelmoula"],
        },
        {
            img: imgData.dresdenCOAImage2101.childImageSharp.fluid,
            title: `Symphoniekonzert - Classic Open Air Dresden`,
            location: `Neumarkt, Dresden`,
            time: `August 2021`,
            fullText: `Nach dem großen Publikumszuspruch im Sommer 2019 wurde die Junge Philharmonie Berlin auch im Covid-Jahr 2021 für das Classic Open Air in Dresden eingeladen, zusammen mit der renommierten Klaviersolistin Khatia Buniatishvili!`,
            conductor: `Julien Salemkour`,
            soloists: ["Khatia Buniatishvili"],
        },
        {
            img: imgData.dresdenCOAImage2102.childImageSharp.fluid,
            title: `Operngala - Classic Open Air Dresden`,
            location: `Neumarkt, Dresden`,
            time: `August 2021`,
            fullText: `Auf das Symphoniekonzert folgend begeisterte eine Operngala mit Sir Bryn Terfel und Barbara Krieger das Dresdner Publikum. Das Orchester glänzte mit einem bunten Programm von Musical bis Wagner.`,
            conductor: `Marcus Merkel`,
            soloists: ["Sir Bryn Terfel", "Barbara Krieger"],
        },
        {
            img: imgData.verdiRequiem23Image.childImageSharp.fluid,
            title: `Verdi: Messa da Requiem`,
            location: `Berliner Dom`,
            time: `November 2023`,
            fullText: `Nach der langen Covid-bedingten Pause wurde die JPB wieder für ein Verdi Requiem in den Berliner Dom eingeladen - wiederum in Zusammenarbeit mit dem Ernst-Senff-Chor Berlin.`,
            conductor: `Marcus Merkel`,
            soloists: ["Barbara Krieger", "Karis Tucker", "Mario Lerchenberger", "Daniel Pannermayr"],
        },
    ]

    const pressData = [
        {
            medium: "IOCO Kultur im Netz",
            author: "Michael Stange",
            date: "11.12.2023",
            link: "https://www.ioco.de/berlin/",
            headline: "Berlin, Berliner Dom, Messa da Requiem - Giuseppe Verdi",
            fullText: ["Durch Steigerungen des Ausdruckes und Veränderungen der Tempi im Verlauf der Sätze wurde Verdis packende klanglichen Wendungen farbenreich dargestellt. Die Kulmination war das bebende Finale. Wirkungsvolle auch die Positionierung der Ferntrompeten auf der Empore, die Im „Tuba mirum“ mit ihren Posaunenstößen das Jüngste Gericht eröffneten. Eine weitere große Leistung war der Zusammenhalt von Orchester, Chor und Solisten, der im akustisch schwierigen Berliner Dom mit seinem langen Nachhall keine Leichtigkeit ist. Die Junge Philharmonie Berlin besteht aus jungen, erfahrenen und hochtalentierten Musikern. Das Orchester spielte ein fein abgestuftes Requiem mit prächtigen Farben und großer Klangschönheit. Ihm gelangen innige, berührende aber auch lebendig auftrumpfende Momente. Ihre Virtuosität und Spielfreude waren ein wesentlicher Motor dieser überragenden Aufführung."],
            highlight: "Die Junge Philharmonie Berlin besteht aus jungen, erfahrenen und hochtalentierten Musikern. Das Orchester spielte ein fein abgestuftes Requiem mit prächtigen Farben und großer Klangschönheit. Ihm gelangen innige, berührende aber auch lebendig auftrumpfende Momente. Ihre Virtuosität und Spielfreude waren ein wesentlicher Motor dieser überragenden Aufführung.",
        },
        {
            medium: "Klassik begeistert",
            author: "Peter Sommeregger",
            date: "05.11.2023",
            link: "https://klassik-begeistert.de/53929-2/",
            headline: "Verdis Totenmesse ist eine Mahnung an die derzeit so martialische Welt",
            fullText: ["Giuseppe Verdis Requiem, vielfach, aber nicht zutreffend als seine beste Oper bezeichnet, entfaltet speziell in einem sakralen Raum besondere Wirkung. Im Berliner Dom nutzt man die Gegebenheiten des Raumes und platziert die Blechbläser für bestimmte Passagen in der ehemaligen Loge des Kaiserhauses, was einen erstaunlichen akustischen Effekt ergibt. Das jugendliche Ensemble der Jungen Philharmonie Berlin, vom Dirigenten Marcus Merkel 2013 gegründet, bestreitet den Abend mit hoher klanglicher Kompetenz, auch den nicht unproblematischen Bedingungen des Sakralraumes wird es gerecht."],
            highlight: "Das jugendliche Ensemble der Jungen Philharmonie Berlin, vom Dirigenten Marcus Merkel 2013 gegründet, bestreitet den Abend mit hoher klanglicher Kompetenz, auch den nicht unproblematischen Bedingungen des Sakralraumes wird es gerecht.",

        },
        {
            medium: "IOCO Kultur im Netz",
            author: "Michael Stange",
            date: "21.05.2020",
            link: "https://www.ioco.de/2020/05/21/berlin-berliner-dom-missa-solemnis-ludwig-van-beethoven-ioco-kritik-21-052020/",
            headline: "Ludwig van Beethoven – furios im Berliner Dom\n" +
                "Missa Solemnis – Überwältigende Klangfluten – kurz vor Corona-Aus",
            fullText: ["Eine weitere große Leistung war der Zusammenhalt von Orchester, Chor und Solisten, der im akustisch schwierigen Berliner Dom mit seinem langen Nachhall keine Leichtigkeit ist.", "Die Junge Philharmonie Berlin besteht aus jungen, erfahrenen und hochtalentierten Musikern. Das Orchester spielte eine fein abgestufte Missa mit prächtigen Farben und von großer Schönheit. Ihm gelangen innige, berührende aber auch lebendig auftrumpfende Momente. Ihre Virtuosität und Spielfreude waren ein wesentlicher Motor dieser überragenden Aufführung. Hört man das Orchester, glaubt man nicht, dass es ein privater Verein ist, der 2013 vom heute überwiegend in Graz tätigen Dirigenten Marcus Merkel gegründet wurde."],
            highlight: "Das Orchester spielte eine fein abgestufte Missa mit prächtigen Farben und von großer Schönheit. Ihm gelangen innige, berührende aber auch lebendig auftrumpfende Momente. Ihre Virtuosität und Spielfreude waren ein wesentlicher Motor dieser überragenden Aufführung.",
        },
        {
            medium: "IOCO Kultur im Netz",
            author: "Michael Stange",
            date: "05.09.2019",
            link: "https://www.ioco.de/2019/09/05/dresden-neumarkt-classic-oper-air-pape-krieger-seiffert-ioco-aktuell-05-09-2019/",
            headline: "6. Classic Open Air  –  Auf dem Dresdner Neumarkt\n" +
                "Barbara Krieger, Peter Seiffert, Rene Pape – Verzaubern in sonnig, südlicher Atmosphäre",
            fullText: ["Dirigent Julien Salemkour trug die Sänger mit immenser Musikalität und großem stilistischem Einfühlungsvermögen auf Händen. Er war ihnen ein umsichtiger, zugewandter und zuhörender Partner. Orchesterfarben und klangliche Durchhörbarkeit gewährleistete er mit sicherer Hand und entlockte der Jungen Philharmonie Berlin sowohl die Farben Kalmans und als auch die dramatischen Klänge Wagners.", "Die von dem heute auch in Graz tätigen Dirigent Marcus Merkel 2013 gegründete Junge Philharmonie Berlin bot Orchesterleistungen auf hohem Niveau. Das aus jungen, motivierten und hochtalentierten Profis bestehende Orchester glänzte durch seinen farbigen Orchesterklang und fulminantes, involviertes Spiel."],
            highlight: "Das aus jungen, motivierten und hochtalentierten Profis bestehende Orchester glänzte durch seinen farbigen Orchesterklang und fulminantes, involviertes Spiel.",
        },
        {
            medium: "IOCO Kultur im Netz",
            author: "Michael Stange",
            date: "16.03.2019",
            link: "https://www.ioco.de/2019/03/16/berlin-berliner-dom-junge-philharmonie-berlin-c-moll-messe-ioco-kritik-16-03-2019/",
            headline: "Große Messe in c-Moll, KV 427  – Wolfgang Amadeus Mozart\n" +
                "Junge Philharmonie Berlin – Mit Feuer, Elan, Hingabe",
            fullText: ["Die Junge Philharmonie Berlin – vor elf Jahren vom Dirigenten Marcus Merkel gegründet – besteht aus jungen, erfahrenen und hochtalentierten Instrumentalisten. Bei ihnen lag die C-Moll Messe von Mozart in besten Händen. Sie sind ein weiteres Spitzenorchester in der Musikstadt Berlin. Zusammen musizierten sie begeistert, jauchzend frisch, pastos und lebendig. Ihre Virtuosität und Spielfreude waren ein wesentlicher Motor dieser überragenden Aufführung. Musikalität und Leidenschaft sind in solcher Qualität selten zu hören.", "Marcus Merkel Dirigat ist es zu danken, dass trotz der schwierigen Akustik des Berliner Doms eine brillante Aufführung gelang. Die Chöre und das Orchester waren fein aufeinander abgestimmt klangen kraftvoll, glänzend, bewegt und anrührend. Die Leitung von Chor und Orchester zeichnete sich durch Feuer, Kraft, aber auch durch ruhige Erhabenheit und innige Momente aus. Merkel entlockte dem Orchester einen ausgefeilten Goldglanz und eine Klangschönheit die ihresgleichen suchen."],
            highlight: "Bei ihnen lag die C-Moll Messe von Mozart in besten Händen. Sie sind ein weiteres Spitzenorchester in der Musikstadt Berlin. Zusammen musizierten sie begeistert, jauchzend frisch, pastos und lebendig.",
        },
        {
            medium: "Am Zeuthener See",
            author: "Karin Mende",
            date: "18.01.2014",
            link: "https://www.zeuthen.de/Am-Zeuthener-See-2014-01-623946.pdf",
            headline: "Drei Zugaben beim Neujahrskonzert \n" +
                "Junge Philharmonie Berlin begeisterte Gäste",
            fullText: ["„Neujahr und Musik das hört sich nicht nur gut an, dass klingt auch gut zusammen. „Ein schöner Abend.“, „Faszinierend, mit welcher Freude das Ensemble gemeinsam musiziert.“, „So festlich, möchte ich das Jahr immer wieder beginnen.“, „Es ist unfassbar, was wir hier nach Zeuthen holen.“. Dies sind nur einige Reaktionen der Gäste des traditionellen Zeuthener Neujahrskonzerts am 18. Januar 2014 im Sport- und Kulturzentrum Zeuthen. Die Junge Philharmonie Berlin begeisterte mit Werken von Johann Sebastian Bach, Benjamin Britten, Edvard Grieg und Pjotr I. Tschaikowski. Das Orchester besteht aus Musikstudenten sowie jungen und erfahrenen Profis, die zusammen begeistert und frisch musizieren!"],
            highlight: "Drei Zugaben beim Neujahrskonzert - Junge Philharmonie Berlin begeisterte Gäste",
        },
    ]

    const selectProject = (evt) => {
        let selected = document.querySelector('.projects--overview--tile__selected');
        if (selected) {
            let selectedDetail = selected.nextSibling;
            selected.classList.remove('projects--overview--tile__selected');
            selected.classList.add('projects--overview--tile__unselected')
            if (selectedDetail) {
                selectedDetail.classList.remove('projects--detail__selected');
                selectedDetail.classList.add('projects--detail__unselected');
            }
        }
        let target = evt.target.closest('.projects--overview--tile');
        let detail = target.nextSibling;
        target.classList.remove('projects--overview--tile__unselected');
        target.classList.add('projects--overview--tile__selected');
        detail.classList.remove('projects--detail__unselected');
        detail.classList.add('projects--detail__selected');
        target.scrollIntoView(true);
    }

    const selectPress = (evt) => {
        let selected = document.querySelector('.press--tile__selected');
        if (selected) {
            let selectedCitation = selected.firstChild,
                selectedDetail = selected.lastChild;
            selected.classList.remove('press--tile__selected');
            selected.classList.add('press--tile__unselected');
            selectedCitation.classList.remove('press--citation__selected');
            selectedCitation.classList.add('press--citation__unselected');
            selectedDetail.classList.remove('press--detail__selected');
            selectedDetail.classList.add('press--detail__unselected');
        }
        let target = evt.target.closest('.press--tile');
        let citation = target.firstChild;
        let detail = target.lastChild;
        target.classList.remove('press--tile__unselected');
        target.classList.add('press--tile__selected');
        citation.classList.remove('press--citation__unselected');
        citation.classList.add('press--citation__selected');
        detail.classList.remove('press--detail__unselected');
        detail.classList.add('press--detail__selected');
    }

    return (
        <Layout location="home">
            <SEO title="Home" lang="de"/>
            <section className="titleScreen" id="title">
                <div className="titleContainer">
                    <div className="gridRow--1 d-grid d-grid--2columns--title">
                        <Img fixed={imgData.logoIcon.childImageSharp.fixed} className="gridCol--1 titleIcon"/>
                        <h1 className="homeTitle title serif gridCol--2">
                            <span className="hover--glow">Junge</span>
                            <br/>
                            <span className="hover--glow">Philharmonie</span>
                            <br/>
                            <span className="hover--glow">Berlin</span>
                            <br/>
                            <span className="serif homeTitle--subtitle">— das junge Orchester der Stadt! </span>
                            <span role="img" className="emoji" aria-label="Instrumental emoji">🎻</span>
                            <Link to="#orchestra">
                                <span role="img" aria-label="Continue" className="emoji continueIcon">
                                    ➡️➡️
                                </span>
                            </Link>
                        </h1>
                    </div>
                    <div className="gridRow--2">

                    </div>
                </div>
            </section>

            {/* BIOGRAPHY ----------------------------------------------- */}
            <div className="verticalSpace--headerHeight" id="orchestra"/>
            <section className="container content bgGray--90 rounded__biggerScreens">
                <h2 className="title title--section serif italic">
                    Eines der führenden jungen Orchester Berlins
                </h2>
                <div className="biography--overview">
                    <table className="biography--overview--table">
                        <tbody>
                        <tr>
                            <td className="biography--overview--label">Gegründet</td>
                            <td className="biography--overview--value"><span
                                className="hover--shadow__white">{biographyOverviewData.Founded}</span></td>
                        </tr>
                        <tr>
                            <td className="biography--overview--label">Dirigenten</td>
                            <td className="biography--overview--value">{biographyOverviewData.Conductors.map(x => (
                                <span key={x}
                                      className='biography--overview--element hover--shadow__white'>{x}</span>))}</td>

                        </tr>
                        <tr>
                            <td className="biography--overview--label">Solisten</td>
                            <td className="biography--overview--value">{biographyOverviewData.Soloists.map(x => (
                                <span key={x}
                                      className='biography--overview--element hover--shadow__white'>{x}</span>))}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="img--container space--Y1-5 space--Bottom2">
                    <Img fluid={imgData.bioImage.childImageSharp.fluid}
                         alt="René Pape, Barbara Krieger, Julien Salemkour, Peter Seiffert und die Junge Philharmonie Berlin beim Classic Open Air Dresden 2019"
                         className="rounded--1 img--hover"
                    />
                    <span className="img--caption">René Pape, Barbara Krieger, Julien Salemkour, Peter Seiffert und die Junge Philharmonie Berlin beim Classic Open Air Dresden 2019</span>
                </div>
                <div className="biography--text">
                    <p className="biography--paragraph">
                        Gegründet wurde die Junge Philharmonie Berlin im Januar 2013 von Marcus Merkel, der
                        das Orchester für die Uraufführung eines großen Oratoriums in der Gethsemanekirche Berlin
                        Prenzlauer Berg zusammenstellte. Das Orchester besteht seither aus <b>jungen, motivierten
                        und hochtalentierten Profis</b>, die in den Konzerten mit <b>Virtuosität und begeisterndem
                        Orchesterklang</b> glänzen. In den folgenden Jahren trat die Junge Philharmonie in
                        verschiedenen Formationen - vom Streichorchester bis zum großen romantischen Sinfonieorchester -
                        und in den verschiedensten
                        Sälen in und um Berlin auf, darunter im großen Saal des Berliner Konzerthauses.
                    </p>
                    <p className="biography--paragraph">
                        Neben der Bereicherung der Berliner Musikszene ist den jungen Musikern auch die <b>intensive
                        Nachwuchsförderung</b> äußerst wichtig - so kooperierte das Orchester von 2015 bis 2018 eng
                        mit dem Projekt „Jugend dirigiert“, das jungen Menschen die Gelegenheit gab, durch die Arbeit
                        mit
                        einem Sinfonieorchester in Proben und Konzerten zu wachsen und Führungserfahrung zu sammeln.
                    </p>
                    <p className="biography--paragraph">
                        Das Repertoire des Orchesters reicht von der Wiener Klassik bis zu Werken des 20. und 21.
                        Jahrhunderts. Mit Mozarts Großer Messe in c Moll trat die Junge Philharmonie Berlin im März
                        2019 <b>erstmals im Berliner Dom</b> auf. Im August 2019 folgte eine große Operngala im Rahmen
                        des <b>Dresdner Classic Open Air</b> mit den Solisten Barbara Krieger, Peter Seiffert und René
                        Pape. Unter
                        der Leitung des Staatskapellmeisters Julien Salemkour spielte das Orchester im November 2019
                        erneut im Berliner Dom, diesmal mit dem Requiem von Giuseppe Verdi, worauf im Februar 2020
                        Beethovens Missa Solemnis folgte.
                    </p>
                    <p className="biography--paragraph">
                        Mitten in der Corona-Krise ergab sich für das Ensemble eine weitere Premiere: Für eine
                        Dokumentation von ARTE zeichneten Musiker der Jungen Philharmonie Berlin unter der Leitung
                        von
                        Marcus Merkel im August 2020 den "Karneval der Tiere" von Camille Saint-Saëns auf.
                    </p>
                    <p className="biography--paragraph">
                        Im Sommer 2021 wurde das Orchester erneut zum <b>Dresdner Classic Open Air</b> eingeladen,
                        dieses Mal wurde mit Khatia Buniatishvili das Klavierkonzert No. 1 von Tschaikowsky aufgeführt
                        und mit den Solisten Sir Bryn Terfel und Barbara Krieger eine Operngala von Wagner bis Operette
                        gestaltet.
                    </p>
                    <p className="biography--paragraph">
                        Nach einer Rückkehr in den Berliner Dom mit dem Verdi-Requiem im November 2023 ist für den
                        Sommer 2024 ein ganz besonders spannendes Vorhaben geplant: Die Junge Philharmonie Berlin ist
                        eingeladen, im Schlosshof vom <b>Schloss Neuschwanstein</b> zwei Konzerte zu spielen, u.a. mit
                        der Symphonie Fantastique von Berlioz.
                    </p>
                    <p className="biography--paragraph">
                        Künstlerischer Leiter der JPB ist seit Gründung der junge Berliner Dirigent <a
                        href="https://www.marcusmerkel.de" target="_blank" rel="noreferrer">Marcus Merkel</a>.
                    </p>
                </div>
                <div className="img--container space--Top1-5">
                    <Img fluid={imgData.bioImage2.childImageSharp.fluid}
                         alt="Die Junge Philharmonie Berlin bei den Aufnahmen vom Karneval der Tiere für ARTE @ Theater im Delphi, Berlin"
                         className="rounded--1 img--hover"
                    />
                    <span className="img--caption">Die Junge Philharmonie Berlin bei den Aufnahmen vom Karneval der Tiere für ARTE @ Theater im Delphi, Berlin</span>
                </div>
            </section>

            {/* PROJECTS ----------------------------------------------- */}
            <div className="verticalSpace--headerHeight" id="projects"/>
            <section className="container content bgGray--80 rounded__biggerScreens">
                <h2 className="title title--section serif italic align-center">
                    – <span role="img" aria-label="rocket emoji">🚀</span> Projekte –
                </h2>
                <div className="projects--overviewContainer">
                    <div className="projects--overview">
                        {projectsData.reverse().map((x, i) => (
                            <React.Fragment key={`Fragment ${i}`}>
                                <div
                                    key={x.title}
                                    role="button"
                                    className="projects--overview--tile projects--overview--tile__unselected"
                                    onClick={(evt) => selectProject(evt)}
                                    onKeyDown={(evt) => selectProject(evt)}
                                    tabIndex={i}
                                >
                                    {x.img && <Img fluid={{...x.img, aspectRatio: 3 / 2}} alt={x.title}
                                                   className="projects--overview--img"/>}
                                    <h3 className="projects--overview--title">{x.title}</h3>
                                    <div className="projects--overview--location">@ {x.location}</div>
                                </div>
                                <div key={`Detail ${x.title}`} className="projects--detail projects--detail__unselected">
                                    <div className="projects--detail--facts">
                                        <p className="projects--detail--fact projects--detail--locationTime"><i><span
                                            role="img" className="emoji pl--0-5" aria-label="location icon">📍</span> {x.location}
                                            <br className="S_only"/>
                                            <span role="img" className="emoji pl--0-5"
                                                  aria-label="date icon">📅</span> {x.time}</i></p>
                                        {x.conductor && <p className="projects--detail--fact"><span
                                            className="projects--detail--factLabel">{x.conductor && "Dirigent:"}</span>{x.conductor && ` ${x.conductor}`}
                                        </p>}
                                        {x.soloists && <p className="projects--detail--fact"><span
                                            className="projects--detail--factLabel">{x.soloists.length > 1 ? "Solisten:" : "Solist:"}</span>
                                            {x.soloists && `${x.soloists.map(y => (" " + y))}`}
                                        </p>}
                                    </div>
                                    <hr className="projects--detail--hr"/>
                                    <div className="projects--detail--fullText">{x.fullText}</div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </section>

            {/* PRESS ----------------------------------------------- */}
            <div className="verticalSpace--headerHeight" id="press"/>
            <section className="container container--fullWidth bgWhite--90 darkText">
                <div className="container content">
                    <h2 className="title title--section serif darkText pt--0-5">
                        Presse <span role="img" className="emoji emoji--rotate pl--0-25" aria-label="newspaper icon">📰</span>
                    </h2>
                    <div className="press--overview">
                        {pressData.map((x, i) => (
                            <div key={`Press ${i}`} className="press--tile press--tile__unselected">
                                <div
                                    key={`Highlight ${i}`}
                                    role={"button"}
                                    className="press--citation press--citation__unselected serif italic"
                                    onClick={(evt) => selectPress(evt)}
                                    onKeyDown={(evt) => selectPress(evt)}
                                    tabIndex={i}
                                >
                                    {x.highlight}
                                </div>
                                <div key={`Detail ${i}`} className="press--detail press--detail__unselected">
                                    <h3 className="press--detail--headline serif italic">{x.headline.split('\n').map((item, key) => (<span key={key}>{item}<br/></span>))}</h3>
                                    <div className="press--detail--whenAndWhere"><a href={x.link} target="_blank" rel="noreferrer" className="press--detail--medium">{x.medium}</a> vom <span className="press--detail--date">{x.date}</span></div>
                                    <div className="press--detail--paragraph"><span className="press--detail--fullText">{x.fullText}</span></div>
                                    <div className="press--detail--by">von <span className="press--detail--author">{x.author}</span></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* SUPPORT ----------------------------------------------- */}
            <div className="verticalSpace--headerHeight" id="support"/>
            <section className="container container--fullWidth container--fullWidth__butForL bgWhite--90 rounded__fromL support">
                <div className="support--titleContainer bgBlue--80">
                    <div className="support--titleContainer__center">
                        <h2 className="title title--section support--title">
                            <b><i>UNTER-<br/>STÜTZEN</i> <br/>SIE UNS <span role="img" className="emoji hover--shadow__white" aria-label="heart icon">❤️</span></b>
                        </h2>
                    </div>
                </div>

                <div className="support--donateContainer">
                    <div className="support--donateContainer__center">
                        <form action="https://www.paypal.com/donate" method="post" target="_top">
                            <input type="hidden" name="hosted_button_id" value="BPLU92EQRN5HE" />
                            <input type="image" src={paypalDonate} className="hover--scale support--donateButton" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/de_DE/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                        <div className="support--subText">
                            <div className="smallText darkText support--checkList">
                                <p className="hover--shift__10 hover--shadow__darkOffset"><span role="img" aria-label="check emoji">✅</span> <b>Einmalig</b> oder <b>regelmäßig</b> spenden</p>
                                <p className="hover--shift__10 hover--shadow__darkOffset"><span role="img" aria-label="check emoji">✅</span> Betrag <b>frei</b> wählbar</p>
                                <p className="hover--shift__10 hover--shadow__darkOffset"><span role="img" aria-label="check emoji">✅</span> <b>Spendenquittung</b> garantiert</p>
                            </div>
                            <p className="smallText darkText support--thankYou"><b>Wir bedanken uns herzlichst für Ihre Unterstützung!</b></p>
                        </div>
                    </div>
                </div>
            </section>

            {/* CONTACT ----------------------------------------------- */}
            <div className="verticalSpace--headerHeight" id="contact"/>
            <section className="container container--66Width content bgGray--80 rounded__biggerScreens contactSection">
                <h2 className="title title--section serif italic">
                    Kontakt <span role="img" className="emoji pl--0-25" aria-label="postal icon">📯️</span>
                </h2>
                <h3 className="title--subtitle serif italic">Buchungen, Anfragen, Projektideen... wir freuen uns!</h3>
                <div className="contactSpace">
                    <ContactForm action="https://www.flexyform.com/f/1fffac32e7977f0fd39c1659edc06b6de59d762c"/>
                </div>
            </section>

        </Layout>
    )
}

export default IndexPage
