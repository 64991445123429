import React from "react"

const ContactForm = (props) => (
    <form className="contactForm" action={props.action} method="post">
        <div>
            <label htmlFor="fullname">Vor- & Nachname</label>
            <input type="text" name="fullname" id="cfName"/>
        </div>
        <div>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="cfMail"/>
        </div>
        <div>
            <label htmlFor="message">Ihre Nachricht an uns</label>
            <textarea name="message" rows="5"/>
        </div>
        <input type="hidden" name="_recaptcha" id="_recaptcha"/>
        <div className="contactForm--submitContainer">
            <button type="submit" className="contactForm--submit"><span role="img" aria-label="mail icon" className="pr--0-25">✉️</span><span>Absenden</span></button>
            <div className="smallText contactForm--rcText">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
        </div>
    </form>
)

export default ContactForm